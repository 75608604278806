body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Raleway'; */
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter';
}

.carousel-container .caption{
  position: absolute;
  bottom: 0px;
  left: 20px;
  font-size: 50px;
  text-align: left;
}
a.navbar-brand.page-scroll.logo_dezinin {
  display: flex;
  padding: 0px;
}
h1.notice_scrolling{
  font-size: 50px;
  font-weight: 600;
  color: #333;
}
span.notice_eco_part, .color_span{
  color:#faae1b !important
}
.half_part_div_sustainability{
  display: flex;
}
.trend_comp_main {
  min-height: 1000px !important;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carosel_part {
  position: relative;
}
.carosel_part p.legend {
  position: absolute;
  left: 6%;
  bottom: 20%;
  font-size: 60px;
  line-height: 70px;
  font-weight: 600;
  text-align: left;
  color: white;
}
.sustainability_main .half_part_div_sustainability,  
.our_story_main .half_part_div_sustainability  {
  display: flex;
  justify-content: center;
  align-items: center;
}
.trend_img_part {
  position: relative;
  text-align: center;
  color: white;
}
.trend_img_part .trend_link_part {
  position: absolute;
  bottom: 20%;
  left: 11%;
  transform: translate(-50%, -50%);
}
.trend_link_part a {
  color: transparent !important;
  width: auto;
  background: #48424269 !important;
  padding: 20px;

}
.trend_link_part a button.explore_all_button_without_background {
  background: transparent;
  color: white;
  width: auto;
  padding: 0px;
  font-size: 18px;
  font-weight: 600;
  padding: 8px;
}
.trend_link_part a:hover{
  background-color: gray !important;
}
.row{
  margin-left: 0px !important;
  margin-right: 0px !important;
}
/* .row.trend_image_full{
  padding-top: 40px;
  padding-bottom: 40px;
} */
.trend_image_full h6.header_text_size {
  margin-left: 2%;
  padding-top: 40px;
  padding-bottom: 40px;
}
h3.trend_home_header.upper_case.header_text_size {
  text-align: center;
}
p.trend_para_p {
  text-align: center;
  font-size: 20px;
  color: #333;
  padding-bottom: 20px;
}
.services_main {
  padding-top: 0px !important;
}